<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-17 11:16:52
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-09-10 16:22:05
 * @Description: 活动评分详情
-->
<template>
  <MDialog
    width="1000px"
    :is-close="true"
    :footer-show="true"
    :modal-data="modalSetting"
    @open="onOpen"
    @close="onClose"
  >
    <template #header>
      <div class="header-left">
        <MHeader :title="modalSetting.title">
          <template #right>
            <span
              v-if="modalSetting.row?.isScoringScore && modalSetting.row?.isScoringDecide"
              class="total-score"
            >
              总分 【{{ ' ' + scoreDetail.getScoreTotal() + ' ' }}】/{{
                ` ${modalSetting.row?.scoringDecideName || '未通过'}`
              }}
            </span>
            <span
              v-else-if="modalSetting.row?.isScoringScore && !modalSetting.row.isScoringDecide"
              class="total-score"
            >
              总分 【{{ ' ' + scoreDetail.getScoreTotal() + ' ' }}】
            </span>
            <span
              v-else-if="!modalSetting.row?.isScoringScore && modalSetting.row?.isScoringDecide"
              :class="modalSetting.row?.scoringDecideName ? 'total-score' : 'total-score-failed'"
            >
              {{ modalSetting.row?.scoringDecideName || '未通过' }}
            </span>
          </template>
        </MHeader>
      </div>
    </template>

    <el-tabs model-value="score">
      <el-tab-pane label="得分" name="score">
        <div class="min-h-200px">
          <el-collapse v-model="expendIds">
            <div v-for="item in scoreDetail.classs" :key="item.id">
              <el-collapse-item :name="item.id">
                <template #title>
                  <div class="class-title-row">
                    <span class="class-title">{{ item.name }}</span>
                    <span v-if="modalSetting.row?.isScoringScore" class="class-score">
                      总分/评分 【{{ ' ' + item.maxTotal + ' / ' + item.getScoreTotal() + ' ' }}】
                    </span>
                  </div>
                </template>
                <div>
                  <el-row class="row row-border font-600">
                    <el-col :span="24" class="col-item">
                      <span :class="['item-label', 'border-right', 'background-color']">
                        评分分类
                      </span>
                      <div class="item-content border-right">评分分类说明</div>
                    </el-col>
                  </el-row>
                  <el-row
                    v-for="(standard, index) in item.standardList"
                    :key="'standard' + index"
                    class="row row-border"
                  >
                    <el-col :span="24" class="col-item">
                      <span
                        :class="[
                          'item-label',
                          'border-right',
                          'background-color',
                          standard.must ? 'required-before' : '',
                        ]"
                      >
                        {{ standard.name }}
                      </span>
                      <div class="item-content border-right">
                        <p v-html="standard.content"></p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-collapse-item>
            </div>
          </el-collapse>
        </div>
      </el-tab-pane>
      <el-tab-pane label="评语" name="comment">
        <div
          v-if="commentContent"
          class="quill-content ql-editor w-e-text-container"
          v-html="commentContent"
        ></div>
        <el-empty v-else description="暂无相关内容"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </MDialog>
</template>

<script setup lang="ts">
  import type { ModalData } from '~/models/common'
  import { type IActivityScore, ScoreDetail } from '~/models/activity'
  import { getActivityScoreDetail, getActivityCommentDetail } from '~/api/activity/info'

  defineOptions({
    name: 'ActivityScoreDetail',
  })
  interface Props {
    modalData: ModalData<IActivityScore>
  }
  const props = withDefaults(defineProps<Props>(), {
    modalData: () => {
      return {
        isShow: false,
        title: '',
      }
    },
  })
  const emits = defineEmits(['close'])
  const modalSetting = ref<ModalData<IActivityScore>>({
    isShow: false,
    title: '参与活动',
    loading: false,
    row: {},
  })
  const expendIds = ref<string[]>([])
  const scoreDetail = ref<ScoreDetail>(new ScoreDetail({}))
  const commentContent = ref('')

  watch(
    () => props.modalData,
    (newValue: ModalData<IActivityScore>) => {
      modalSetting.value = newValue
    },
    {
      immediate: true,
    }
  )

  const onOpen = () => {
    if (modalSetting.value.row?.id) {
      const params = {
        participantId: modalSetting.value.row?.id,
      }
      modalSetting.value.loading = true
      getActivityScoreDetail(params)
        .then((res) => {
          const data = res.data
          if (data) {
            scoreDetail.value = new ScoreDetail(data)
            expendIds.value = scoreDetail.value.classs?.map((item) => item.id as string) || []
          }
        })
        .finally(() => {
          modalSetting.value.loading = false
        })
      // 获取评语
      if (modalSetting.value?.row?.commentId) {
        getActivityCommentDetail(modalSetting.value.row.commentId).then((res) => {
          if (res) {
            commentContent.value = res.data.comment
          }
        })
      }
    }
  }
  const onClose = () => {
    commentContent.value = ''
    emits('close')
  }
</script>

<style scoped lang="scss">
  @import url('../components/table-setting/index.scss');

  .header-left {
    @apply flex flex-justify-between;
    .total-score {
      @apply fw-bold font-s-1.5rem mr-25px;
      color: var(--el-color-primary);
    }
    .total-score-failed {
      @apply fw-bold font-s-1.5rem mr-25px;
      color: var(--el-color-error);
    }
  }

  :deep(.el-collapse-item) {
    border-bottom: 1px solid var(--el-collapse-border-color);
    margin-bottom: 0;
  }
  :deep(.el-collapse-item__header) {
    border-bottom: none;
  }
  :deep(.el-collapse) {
    border-bottom: none;
  }
  .row {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
  .class-title-row {
    @apply flex flex-justify-between flex-grow-1 pr-20px fw-bold font-s-1.2rem;
    .class-score {
      color: #27ceac;
    }
  }
</style>
